@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face { font-family: "Roboto"; font-weight: bold;  src: url("fonts/Roboto-Black.ttf");}
@font-face { font-family: "Roboto"; font-weight: bold; font-style: italic, oblique;  src: url("fonts/Roboto-BlackItalic.ttf");}
@font-face { font-family: "Roboto"; font-weight: bold;  src: url("fonts/Roboto-Bold.ttf");}
@font-face { font-family: "Roboto"; font-weight: bold; font-style: italic, oblique;  src: url("fonts/Roboto-BoldItalic.ttf");}
@font-face { font-family: "Roboto"; font-style: italic, oblique;  src: url("fonts/Roboto-Italic.ttf");}
@font-face { font-family: "Roboto"; font-weight: light;  src: url("fonts/Roboto-Light.ttf");}
@font-face { font-family: "Roboto"; font-weight: light; font-style: italic, oblique; src: url("fonts/Roboto-LightItalic.ttf");}
@font-face { font-family: "Roboto"; font-weight: medium;  src: url("fonts/Roboto-Medium.ttf");}
@font-face { font-family: "Roboto"; font-weight: medium; font-style: italic, oblique; src: url("fonts/Roboto-MediumItalic.ttf");}
@font-face { font-family: "Roboto"; font-weight: normal; src: url("fonts/Roboto-Regular.ttf");}

@font-face {font-family: "Avenir"; src:url("fonts/Avenir.ttc");}

html{
  scroll-behavior: smooth;
  scroll-padding-top: 47px;
}

body {
  margin: 0;
  font-family: 'Roboto', "Arial" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
